import useGoogleTracking from '@hooks/useGoogleTracking'
import useRedirectToStandard from '@hooks/useRedirectToStandard'
import useTagmanager from '@hooks/useTagmanager'
import useUserCentrics from '@hooks/useUserCentrics'
import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { PageProps, StoryblokPage, transformToNested } from 'pages/sunpoint'
import React, { useEffect } from 'react'

import Box from '@components/atoms/Box'
import {
  BackupMetaWellmaxx,
  MetaTags,
} from '@components/molecules/MetaTags/MetaTags'
import WellmaxxTemplateDefault from '@components/templates/WellmaxxTemplateDefault'

import RealEnvironmentContext from '@helper/context/RealEnviromentContext'
import GoogleApiScriptLoaderWrapper from '@helper/GoogleApiScriptLoaderWrapper'

interface StoryblokComponent {
  component: string
  _uid: string
  [key: string]: any
}

export default function Home({
  story: initialStory,
  menuData,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const story = useStoryblokState(initialStory)
  useRedirectToStandard()
  useUserCentrics()
  useTagmanager()
  const { trackPageView } = useGoogleTracking()
  const router = useRouter()

  useEffect(() => {
    trackPageView(router.asPath, story?.name ?? '')
  }, [router.asPath, story?.name, trackPageView])

  return (
    <WellmaxxTemplateDefault menuData={transformToNested(menuData)}>
      {story?.content.metatags !== undefined && (
        <MetaTags
          _uid={story?.content.metatags._uid}
          title={story?.content.metatags.title}
          plugin={story?.content.metatags.plugin}
          og_image={story?.content.metatags.og_image}
          og_title={story?.content.metatags.og_title}
          description={story?.content.metatags.description}
          twitter_image={story?.content.metatags.twitter_image}
          twitter_title={story?.content.metatags.twitter_title}
          og_description={story?.content.metatags.og_description}
          twitter_description={story?.content.metatags.twitter_description}
        />
      )}
      {story?.content.metatags === undefined && <BackupMetaWellmaxx />}
      <Script
        data-settings-id={process.env.userCentricsId ?? ''}
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
      ></Script>
      <GoogleApiScriptLoaderWrapper>
        <RealEnvironmentContext.Provider value={{ isRealEnvironment: true }}>
          {(!story || !story.content) && <Box>Loading...</Box>}
          {story && story.content && (
            <StoryblokComponent blok={story.content} />
          )}
        </RealEnvironmentContext.Provider>
      </GoogleApiScriptLoaderWrapper>
    </WellmaxxTemplateDefault>
  )
}

export const getStaticProps: GetStaticProps<PageProps> = async (
  context: any
) => {
  const storyblokApi = getStoryblokApi()
  let story
  let links

  try {
    let { data: storyData } = await storyblokApi.get(`cdn/stories/wellmaxx/`, {
      version: 'draft',
    })
    if (storyData && storyData.story) {
      story = storyData.story
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Story-Daten:', error)
  }

  try {
    let { data: linkData } = await storyblokApi.get('cdn/links', {
      version: 'draft',
      per_page: 5000,
      starts_with: 'wellmaxx',
    })
    if (linkData) {
      links = linkData.links
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Links:', error)
  }
  if (story && links) {
    return {
      props: {
        story: story,
        menuData: links,
      },
      revalidate: 20,
    }
  } else {
    return {
      props: {
        story: {
          alternates: [],
          content: { body: [], metatags: {} },
          created_at: '',
          full_slug: '',
          group_id: '',
          id: 0,
          name: '',
          parent_id: 0,
          position: 0,
          slug: '',
          sort_by_date: null,
          tag_list: [],
          uuid: '',
          is_startpage: false,
          meta_data: {},
          published_at: '',
          first_published_at: '',
          lang: '',
        },
        menuData: {},
      },
      revalidate: 20,
    }
  }
}
